<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CCard>
          <CCardBody>
            <CButton color="primary" @click="create()"
              ><CIcon name="cil-plus" /> Create New Language</CButton
            >
            <br />
            <br />
            <br />
            <CAlert :color="alertType" v-if="message"> {{ message }}</CAlert>
            <CDataTable
              hover
              :items="items"
              :fields="fields"
              :items-per-page="50"
              pagination
            >
              <template #languageCode="{ item }">
                <td>
                  <strong>{{ item.languageCode }}</strong>
                </td>
              </template>
              <template #datetimeFormat="{ item }">
                <td>
                  {{ item.datetimeFormatWidget ? item.datetimeFormatWidget : "" }}
                </td>
              </template>
              <template #EDIT="{ item }">
                <td>
                  <CButton color="primary"  @click="edit(item.id)"><CIcon name="cil-pencil" /> Edit</CButton>
                </td>
              </template>
              <template #DELETE="{ item }">
                <td>
                  <CButton color="danger" @click="deleteLanguage(item.id)"
                    >Delete</CButton
                  >
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import axios from "axios";

export default {
  name: "PartnerLanguages",
  data: () => {
    return {
      token: localStorage.getItem("api_token"),
      partnerId: localStorage.getItem("partner_id"),
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      items: [],
      fields: ["languageCode", "datetimeFormat", "EDIT", "DELETE"],

      //Alert
      alertType: "danger",
      message: null,
    };
  },
  computed: {},
  methods: {
    getRowCount(items) {
      return items.length;
    },
    deleteLanguage(id) {
      let self = this;
      axios
        .delete(
          `${this.$apiAdress}/v1/PartnerLanguage/${id}`
        )
        .then(function () {
                    self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
          self.message = "Successfully deleted language.";
          self.getList();
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    create() {
      this.$router.push({ path: `languages/create/${this.emptyGuid}` });
    },
    edit(id){
        this.$router.push({path: `languages/create/${id}`})
      },
    getList() {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/PartnerLanguage`)
        .then(function (response) {
          self.items = response.data;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
  },
  mounted: function () {
    this.getList();
  },
};
</script>

<style scoped>
.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
</style>
